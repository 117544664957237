<template>
  <div v-if="!!options?.length" class="linked-to">
    <icon-indicator
      v-if="type === 'icon-indicator'"
      :indicator="{ amount: options.length, background: 'light-blue-vivid', color: 'static-black-90' }"
      :backdrop="backdrop"
      :icon="defaultIcon"
      :action="() => (isOpen = true)"
      :errors="errors"
      class="linked-to__icon-indicator"
      ref="btn"
    />
    <template v-else-if="type === 'icon'">
      <span
        class="linked-to__icon"
        tabindex="1"
        @click="() => (isOpen = true)"
        @keydown.enter="() => (isOpen = true)"
        ref="btn"
        v-class-mod:linked-to__icon="{ hasWarning: errors.length }"
      >
        <template v-for="(option, key) in options" :key="key">
          <icon-wrapper
            v-if="'explanation' in option && !option?.hide"
            :tooltip="{ title: option.explanation ?? '-' }"
            icon="warning"
            size="medium"
            color="yellow-vivid"
            v-bind="option.icon"
            class="base-label__warning"
          />
        </template>
      </span>
      <span v-if="backdrop" class="linked-to__backdrop" v-set-colors="{ background: backdrop }" />
    </template>
    <modal-wrapper
      v-if="isOpen"
      :stick-to="btn"
      :stick-to-options="{ stick: 'right', align: 'left', enableCover: true }"
    >
      <modal-box
        :title="title || translations.linkedTo.title"
        :disable-content-padding="true"
        :icon="icon ?? defaultIcon"
        :is-loading="isLoading"
        :disableIcon="disableModalIcon || undefined"
        @close="() => (isOpen = false)"
        @click-outside="() => (isOpen = false)"
      >
        <div class="linked-to">
          <template v-for="(option, key) in options" :key="key">
            <template v-if="!option?.hide">
              <cta-button
                v-if="'title' in option"
                :title="option.title"
                :action="option.action"
                :remove-border="true"
                :icon-before="option?.iconBefore || 'read_more'"
                theme="transparent"
              />
              <info-section
                v-else-if="'explanation' in option"
                :explanation="option.explanation"
                :icon="option?.icon || { icon: 'error', color: 'yellow-vivid' }"
              />
            </template>
          </template>
        </div>
      </modal-box>
    </modal-wrapper>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { vSetColors } from "@horizon56/directives";
import { IconName } from "@horizon56/fonts/types";
import { ColorName } from "@horizon56/styles/types";

import { translations } from "@/infrastructure/translations";

import CtaButton, { CtaButtonProps } from "@/components/buttons/cta-button.vue";
import InfoSection, { InfoSectionProps } from "@/components/content/info-section.vue";
import IconIndicator from "@/components/icons/icon-indicator.vue";
import IconWrapper from "@/components/icons/icon-wrapper.vue";
import ModalBox from "@/components/modal/modal-box.vue";
import ModalWrapper from "@/components/modal/modal-wrapper.vue";

const defaultIcon: IconName = "link";
type Hide = { hide?: boolean };
type Link = Pick<CtaButtonProps, "action" | "title" | "iconBefore"> & Hide;
type Warning = Pick<InfoSectionProps, "icon" | "explanation"> & Hide;

const props = withDefaults(
  defineProps<{
    type?: "icon-indicator" | "icon";
    title?: string;
    icon?: IconName | null;
    disableModalIcon?: boolean;
    options: (Link | Warning)[];
    backdrop?: ColorName;
    isLoading?: boolean;
  }>(),
  {
    type: "icon-indicator",
  },
);

defineExpose({ setIsOpen: (is: boolean) => (isOpen.value = is) });

const emits = defineEmits<{ (e: "is-open", a: boolean): void }>();

const btn = ref();
const isOpen = ref(false);

const errors = computed(() =>
  props.options.filter((l): l is Warning => "explanation" in l && !l?.hide).map((l) => l.explanation ?? ""),
);

watch(
  () => isOpen.value,
  (is) => emits("is-open", is),
);
</script>

<style lang="scss" scoped>
.linked-to {
  display: inline-flex;
  flex-flow: column;
  position: relative;
  &__icon {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    padding: 2px 6px;
    background: var(--black-10);
    height: var(--app-button-height-medium);
    border-radius: var(--app-button-height-medium);
    min-width: var(--app-button-height-medium);
    flex-shrink: 0;
    cursor: pointer;
    @include icon-before($icon-link) {
      font-size: var(--app-icon-size-medium);
      color: var(--black-50);
    }
    &:hover {
      background: var(--black-20);
      &:before {
        color: var(--black-90);
      }
    }
    &--hasWarning {
      padding: 2px 8px;
    }
  }
  &__icon,
  &__icon-indicator {
    position: relative;
    z-index: 1;
  }
  &__backdrop {
    position: absolute;
    @include position-corner;
    z-index: 0;
    border-radius: var(--app-button-height-small);
  }
  &:has(#{&}__backdrop) #{&}__icon {
    background: transparent;
    &:hover {
      background: var(--black-10);
    }
  }
}
</style>
